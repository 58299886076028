import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import {isAuthReducer} from "./Reducers/isAuthReducer";
import {workerReducer} from "./Reducers/workerReducer";
import {menuReducer} from "./Reducers/menuReducer";
import {popupReducer} from "./Reducers/popup";
import {panelReducer} from "./Reducers/bottomPanel";
import {registrationReducer} from "./Reducers/registerReducer";
import HairDressers from "../mainComponents/hairdressers/hairDressers";
import React from "react";
import BarberShops from "../mainComponents/barbershops/barberShops";
import BeautySaloon from "../mainComponents/beautysaloon/beautySaloon";
import SpaSaloons from "../mainComponents/spasaloons/spaSaloons";
import TattooSaloons from "../mainComponents/tattoosaloons/tattooSaloons";
import {animationReducer} from "./Reducers/animation";
import {searchPageReducer} from "./Reducers/searchPageReducer";
import {searchReducer} from "./Reducers/searchReducer";
import {searchTextReducer} from "./Reducers/searchTextReducer";


const defaultState = {
    login: '',
    username: 'Инкогнито',
};

const selects = [
    {id: 1, name: 'Парикмахерская', imgUrl: '/img/parm.jpg', path: '/hairdressers', page: <HairDressers/>},
    {id: 2, name: 'Барбершоп', imgUrl: '/img/barbm.jpg', path: '/barbershops', page: <BarberShops/>},
    {id: 3, name: 'Салон красоты', imgUrl: '/img/hcm.jpg', path: '/beautysaloons', page: <BeautySaloon/>},
    {id: 4, name: 'SPA салон', imgUrl: '/img/spam.jpg', path: '/spasaloons', page: <SpaSaloons/>},
    {id: 5, name: 'Tattoo салон', imgUrl: '/img/tatm.jpg', path: '/tattoosaloons', page: <TattooSaloons/>},
];

const hairdressers = [
    {id: 1, name: 'Ромашка', imgUrl: '/img/parm.jpg'},
    {id: 2, name: 'Пизант', imgUrl: '/img/parm.jpg'},
    {id: 3, name: 'Чики чики', imgUrl: '/img/parm.jpg'},
    {id: 4, name: 'Ножницы', imgUrl: '/img/parm.jpg'},
    {id: 5, name: 'Прядь', imgUrl: '/img/parm.jpg'},
];

const barbershops = [
    {id: 1, name: 'Брутал', imgUrl: '/img/barbm.jpg'},
    {id: 2, name: 'Борода', imgUrl: '/img/barbm.jpg'},
    {id: 3, name: 'Бритва', imgUrl: '/img/barbm.jpg'},
    {id: 4, name: 'Стриг мэн', imgUrl: '/img/barbm.jpg'},
    {id: 5, name: 'Барбер', imgUrl: '/img/barbm.jpg'},
    {id: 6, name: 'Барбершоп', imgUrl: '/img/barbm.jpg'},
    {id: 7, name: 'Братья Брю', imgUrl: '/img/barbm.jpg'},
    {id: 8, name: 'Мужик', imgUrl: '/img/barbm.jpg'},
    {id: 9, name: 'Лихие', imgUrl: '/img/barbm.jpg'},
];

const beautysaloons = [
    {id: 1, name: 'Чёлка', imgUrl: '/img/hcm.jpg'},
    {id: 2, name: 'Коса', imgUrl: '/img/hcm.jpg'},
    {id: 3, name: 'Роскошь', imgUrl: '/img/hcm.jpg'},
];

const spasaloons = [
    {id: 1, name: 'Спа мен', imgUrl: '/img/spam.jpg'},
    {id: 2, name: 'name 2', imgUrl: '/img/spam.jpg'},
    {id: 3, name: 'name 3', imgUrl: '/img/spam.jpg'},
    {id: 4, name: 'name 4', imgUrl: '/img/spam.jpg'},
    {id: 5, name: 'name 5', imgUrl: '/img/spam.jpg'},
    {id: 6, name: 'name 6', imgUrl: '/img/spam.jpg'},
    {id: 7, name: 'name 7', imgUrl: '/img/spam.jpg'},
];

const tattoosaloons = [
    {id: 1, name: 'name 1', imgUrl: '/img/tatm.jpg'},
    {id: 2, name: 'name 2', imgUrl: '/img/tatm.jpg'},
    {id: 3, name: 'name 3', imgUrl: '/img/tatm.jpg'},
    {id: 4, name: 'name 4', imgUrl: '/img/tatm.jpg'},
    {id: 5, name: 'name 5', imgUrl: '/img/tatm.jpg'},
];

const hairdressersReducer = (state = hairdressers) => {
    return state
};
const barbershopsReducer = (state = barbershops) => {
    return state
};
const beautysaloonsReducer = (state = beautysaloons) => {
    return state
};
const spasaloonsReducer = (state = spasaloons) => {
    return state
};
const tattoosaloonsReducer = (state = tattoosaloons) => {
    return state
};


const mainInfoReducer = (state = selects) => {
    return state
};


const loginReducer = (state = defaultState.username, action) => {
    if (action.type === "USER_REGISTER") {
        return action.payload;
    } else {
        return state
    }
};

const rootReducer = combineReducers({
        auth: isAuthReducer,
        worker: workerReducer,
        menu: menuReducer,
        popup: popupReducer,
        panel: panelReducer,
        login: loginReducer,
        mainInfo: mainInfoReducer,
        registration: registrationReducer,
        hairdressers: hairdressersReducer,
        barbershops: barbershopsReducer,
        beautysaloons: beautysaloonsReducer,
        spasaloons: spasaloonsReducer,
        tattoosaloons: tattoosaloonsReducer,
        animation: animationReducer,
        searchPage: searchPageReducer,
        search: searchReducer,
        searchText: searchTextReducer,
    }
);

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
);