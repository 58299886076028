import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import q from './SinglePage.module.css'
import {useDispatch, useSelector} from "react-redux";

const SinglePage = ({tempData}) => {

    const panel = useSelector(state => state.panel.panel);

    const animation = useSelector(state => state.animation.animation);
    const dispatch = useDispatch();



    useEffect(() => {
        setTimeout(() => dispatch({type: "ANIMATION_TRUE"}),100)
    }, []);



    const location = useLocation();
    let sl = location.pathname.slice(1).split('/');
    let info = tempData[sl[0]].filter(item => item.id === Number(sl[1]))[0];

    return (
        <div className={panel
            ? animation ? [q.singlePage, q.anim].join(' ') : q.singlePage
            : animation ? [q.singlePage, q.anim, q.full].join(' ') : [q.singlePage, q.full].join(' ')
        } style={{backgroundImage: `url(${process.env.PUBLIC_URL + info.imgUrl})`}}>
            {/*<h5>{location.pathname.slice(1)}</h5>*/}
            <h5>{info.name}</h5>
        </div>
    );
};

export default SinglePage;