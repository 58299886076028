import React from 'react';
import {useLocation} from "react-router-dom";

const BarberShops = () => {
    let location = useLocation();

    return (
        <div>
            <h1>{location.pathname.slice(1)}</h1>
        </div>
    );
};

export default BarberShops;