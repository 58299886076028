let defaultState = {registration: false};

export const registrationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "REGISTRATION_AN":
            // return {...state, isAuth: !state.isAuth};
            return {...state, registration: true};
        case "REGISTRATION_DIS":
            return {...state, registration: false};
        default:
            return state
    }
};