import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import q from './favoritePage.module.css'
import {useDispatch, useSelector} from "react-redux";



const FavoritesPage = () => {
    const animation = useSelector(state => state.animation.animation);
    const dispatch = useDispatch();



    useEffect(() => {
        setTimeout(() => dispatch({type: "ANIMATION_TRUE"}),100)
    }, []);





    const location = useLocation();
    return (
        <div className={
            animation ? [q.favPage, q.anim].join(' ') : q.favPage
        }>
            <h5>{location.pathname.slice(1)}</h5>
            <h4>favPage</h4>
        </div>
    );
};

export default FavoritesPage;