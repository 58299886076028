import React, {useEffect} from 'react';
import w from './Content.module.css';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";


const Content = () => {

    const main = useSelector(state => state.mainInfo);
    let history = useHistory();
    const animation = useSelector(state => state.animation.animation);
    const dispatch = useDispatch();


    useEffect(() => {
        setTimeout(() => dispatch({type: "ANIMATION_TRUE"}), 100)
    }, []);


    const nextPage = (arg) => {
        dispatch({type: "ANIMATION_FALSE"});
        setTimeout(() => history.push(arg), 600)
    };

    return (

        <div className={w.content}>
            {main.map(q => {
                return (
                    <div key={q.id} className={
                        animation ? [w.box, w.anim].join(' ') : w.box
                    }>
                        <div className={w.contentBox}
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL + q.imgUrl})`}}
                             onClick={() => nextPage(q.path)}>
                            <div className={w.title}>{q.name}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Content;