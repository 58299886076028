let defaultState = {search: false};

export const searchReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SEARCH_TRUE":
            return {...state, search: true};
        case "SEARCH_FALSE":
            return {...state, search: false};
        default :
            return state
    }
};