import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import q from './RecordPage.module.css'
import {useDispatch, useSelector} from "react-redux";

const RecordsPage = () => {
    const location = useLocation();
    const animation = useSelector(state => state.animation.animation);
    const dispatch = useDispatch();


    useEffect(() => {
        setTimeout(() => dispatch({type: "ANIMATION_TRUE"}),100)
    }, []);


    return (
        <div className={
            animation ? [q.recPage, q.anim].join(' ') : q.recPage
        }>
            <h5>{location.pathname.slice(1)}</h5>
        </div>
    );
};

export default RecordsPage;