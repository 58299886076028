let defaultState = {searchPage: false};

export const searchPageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SEARCH_SHOW":
            return {...state, searchPage: true};
        case "SEARCH_HIDE":
            return {...state, searchPage: false};
        case "TOGGLE_SEARCH":
            return {...state, searchPage: !state.searchPage};
        default :
            return state
    }
};