let defaultState = {panel: false};

export const panelReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SHOW_PANEL":
            return {...state, panel: true};
        case "HIDE_PANEL":
            return {...state, panel: false};
        case "TOGGLE_PANEL":
            return {...state, panel: !state.panel};
        default :
            return state
    }
};