let defaultState = {animation: false};

export const animationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ANIMATION_TRUE":
            return {...state, animation: true};
        case "ANIMATION_FALSE":
            return {...state, animation: false};
        default :
            return state
    }
};