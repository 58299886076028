import React, {useEffect, useState, lazy, Suspense} from 'react';
import w from './Main.module.css'
import {useDispatch, useSelector} from "react-redux";
// import Panel from "../UI/panel/Panel";
// import Menu from "../menu/Menu";
import Content from "../content/Content";
import {Redirect, Route, Switch} from "react-router-dom";
import GeneralPage from "../pages/generalPage/GeneralPage";
import FavoritesPage from "../pages/favoritesPage/favoritesPage";
import RecordsPage from "../pages/recordsPage/RecordsPage";
import SinglePage from "../pages/singlePage/SinglePage";
// import PrevPage from "../pages/prevPage/prevPage";
// import SearchPage from "../map/SearchPage";


const Panel = lazy(() => import("../UI/panel/Panel"));
const SearchPage = lazy(() => import("../map/SearchPage"));
const PrevPage = lazy(() => import("../pages/prevPage/prevPage"));
const Menu = lazy(() => import("../menu/Menu"));

const Main = () => {


    const dispatch = useDispatch();
    const main = useSelector(state => state.mainInfo);
    const tempData = useSelector(state => state);
    const [prev, setPrev] = useState(true);
    const showPage = useSelector(state => state.searchPage.searchPage);

    const toggleMenu = (arg) => {
        dispatch({type: "TOGGLE_MENU"});
        dispatch({type: 'REGISTRATION_DIS'});
        dispatch({type: "TOGGLE_PANEL"});
        if (arg === 'ex') {
            dispatch({type: "HIDE_PANEL"});
            setTimeout(() => {
                dispatch({type: 'LOGIN_OUT'});
            }, 600);
        }
    };

    const qq = () => {
        dispatch({type: "TOGGLE_MENU"});
        dispatch({type: "HIDE_PANEL"});
    };

    const getPage = (arg) => {
        const qaz = tempData[arg.slice(1)];
        return qaz.map(q => q.id)
    };


    const routs = [
        {path: '/', Component: Content},
        {path: '/favorites', Component: FavoritesPage},
        {path: '/records', Component: RecordsPage},
    ];

    const renderLoader = () => <p>Loading</p>;

    return (
        <div className={showPage
            ? [w.main, w.pad].join(' ')
            : w.main
        }>

            <Suspense fallback={renderLoader()}>
                <SearchPage/>
            </Suspense>

            <Suspense fallback={renderLoader()}>
                <PrevPage setPrev={setPrev}/>
            </Suspense>

            <Suspense fallback={renderLoader()}>
                <Menu toggleMenu={toggleMenu} qq={qq}/>
            </Suspense>
            <Switch>
                {routs.map(({path, Component}) => <Route key={path} exact path={path}><Component/></Route>)}
                {main.map(q =>
                    <Route key={q.path} exact path={q.path}>
                        <GeneralPage/>
                    </Route>)}
                {main.map(q =>
                    getPage(q.path).map(tn =>
                        <Route key={q.path} exact path={q.path + '/' + tn}>
                            <SinglePage tempData={tempData}/>
                        </Route>))
                }
                <Redirect to={<Content/>}/>
            </Switch>

            <Suspense fallback={renderLoader()}>
                <Panel toggleMenu={toggleMenu} qq={qq}/>
            </Suspense>

            {/*<SearchPage />*/}

        </div>
    );
};

export default Main;