import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import q from './generalPage.module.css'


const GeneralPage = () => {
    const location = useLocation();
    const tempData = useSelector(state => state[location.pathname.slice(1)]);
    const animation = useSelector(state => state.animation.animation);
    const searchText = useSelector(state => state.searchText.searchText);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({type: "SEARCH_TRUE"})
    }, [tempData]);

    const openPage = (arg) => {
        history.push(location.pathname + '/' + arg)
    };

    useEffect(() => {
        setTimeout(() => dispatch({type: "ANIMATION_TRUE"}), 100);
    }, []);


    const nextPage = (arg) => {
        dispatch({type: "ANIMATION_FALSE"});
        dispatch({type: "SEARCH_FALSE"});
        setTimeout(() => history.push(location.pathname + '/' + arg), 600);
    };

    const [tempItems, setTempItems] = useState(tempData);

    useEffect(() => {
        if (searchText !== '') {
            setTempItems(tempData.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase())));
        } else {
            setTempItems(tempData)
        }
    }, [searchText]);


    return (
        <div className={
            q.gContent
        }>

            {tempItems && tempItems.map(temp => {
                return (
                    <div key={temp.id} className={
                        animation ? [q.box, q.anim].join(' ') : q.box
                    }
                         style={{backgroundImage: `url(${process.env.PUBLIC_URL + temp.imgUrl})`}}
                         onClick={() => nextPage(temp.id)}>
                        {temp.name}
                    </div>
                )
            })}
        </div>
    );
};

export default GeneralPage;