import './App.css';
import React from "react";
import Main from "./components/main/Main";
import {HashRouter as Router} from 'react-router-dom'


function App() {
    return (
        <div className="App">
            <Router hashType={"noslash"} >
                <Main/>
            </Router>
        </div>
    );
}

export default App;
